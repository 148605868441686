
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Correlation } from './CorrelatedFields.vue'
  import { fixPrice } from '@/utils/general'

interface Quota {
  id: number
  payments: string
  amount: number
}

interface Term {
  name?: string
  payments: number
}

@Component({
  methods: { fixPrice },
})
  export default class Quotas extends Vue {
  @Prop({ type: Object }) pie: Correlation
  @Prop({ type: Array, default: () => [{ payments: 48 }, { payments: 32 }, { payments: 24 }] }) terms: Array<Term>

  get quotas (): Array<Quota> {
    const { pie } = this
    if (!pie) return []

    const { debt } = pie

    return this.terms.map(({ payments }, id) => {
      return {
        id,
        payments: `${payments} Pagos`,
        amount: debt / payments,
      }
    })
  }
  }
